// 联想查询
// http://api-beta.haolvtrip.com/doc.html#/tmc-biz/hotel-search-controller/searchThinkUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_hotel_search_searchThink = (data) => {
  const p = {
    method: 'post',
    urlSuffix: '/consumer/hotel/search/searchThink',
    data,
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_hotel_search_searchThink;