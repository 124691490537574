/*
*差标查询  http://219.128.52.2:18765/doc.html#/haolv-biz/t-bd-policy-config-controller/travelPolicyTipsUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_policyConfig_travelPolicyTips = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/travelPolicyTips',
        data: data
    };
    return __request(pParameter)
};
export default consumer_web_policyConfig_travelPolicyTips;