const __request = require(`./__request/__request_contentType_json`);

const consumer_hotel_search_getHotelBrandList = () => {
    const p = {
        method: 'post',
        urlSuffix: '/consumer/hotel/search/getHotelBrandList'
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p))
    }).catch((err) => {
        console.log('接口 consumer_hotel_search_getHotelBrandList 异常')
        throw(err)
    })
};
module.exports = consumer_hotel_search_getHotelBrandList;