// consumer_journey_getEvectionDetail 根据出差单号获取详情
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-journey-controller/getEvectionDetailUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_journey_getEvectionDetail = (pParameter) => {
    if (!pParameter) pParameter = {};
    // if (!pParameter.pageType) pParameter.pageType = 2;
    const p = {
        method: 'post',
        urlSuffix: '/consumer/journey/getEvectionDetail',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_getEvectionDetail;
