//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 品牌列表
import consumer_hotel_search_getHotelBrandList
    from "@/lib/data-service/haolv-default/consumer_hotel_search_getHotelBrandList.js";
import HotelAreaSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-area-selector/1.0.1/index.vue'

export default {
    props: {
        cityId: {
            type: String,
            default: '70082',
        },
        cityName: {
            type: String,
            default: '',
        },
        evectionDetailType: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            popularList: [
                {
                    id: 1,
                    name: '符合差标',
                    isSelected: false
                },
                {
                    id: 2,
                    name: '协议酒店',
                    isSelected: false
                }
            ],
            differenceEnable: 0,
            agreementEnable: 0,
            filterList: [
                {
                    name: '1',
                    label: '位置区域',
                    height: 26,
                    isSelected: false
                },
                {
                    name: '2',
                    label: '星级/价格',
                    height: 26,
                    isSelected: false
                },
                {
                    name: '3',
                    label: '品牌连锁',
                    height: 26,
                    isSelected: false
                }
            ],//筛选的第一层分类列表
            filterActive: '1',//选中的筛选第一层分类对应的name

            selectedArea: {},//已选区域位置的坐标
            districtActive: '',//已选的行政区筛选条件

            starList: [
                {
                    id: '2',
                    name: '≤二星/经济',
                    isSelected: false
                },
                {
                    id: '3',
                    name: '三星/舒适型',
                    isSelected: false
                },
                {
                    id: '4',
                    name: '四星/高档型',
                    isSelected: false
                },
                {
                    id: '5',
                    name: '五星/豪华型',
                    isSelected: false
                },
            ],//星级list
            starValues: [],//已选的星级idlist
            selectedStarList: [],//已选的星级对象list

            minValue: '',//最低价
            maxValue: '',//最高价
            selectedPrice: '',//已填的价格范围提示标签
            isAvailablePrice: true,//价格范围是否有效

            brandClassifiedList: [
                {
                    id: 1,
                    name: '快捷连锁',
                    isSelected: true
                },
                {
                    id: 2,
                    name: '中端连锁',
                    isSelected: false
                },
                {
                    id: 3,
                    name: '高端连锁',
                    isSelected: false
                },
                {
                    id: 4,
                    name: '其他品牌',
                    isSelected: false
                },
            ],//品牌连锁分类list
            showBrandPopperActive: '',//当前需要显示的品牌连锁弹框type
            currBrandList: [],//当前显示的品牌连锁list
            selectedBrandList: [],//已选的品牌连锁list

            sortActive: 1,//使用排序条件对应的条件id
            sortList: [
                {
                    id: 1,
                    code: 0,
                    name: '推荐排序',
                    isSelected: true
                },
                {
                    id: 2,
                    code: 2,
                    name: '价格低到高',
                    isSelected: false
                },
                {
                    id: 3,
                    code: 3,
                    name: '价格高到低',
                    isSelected: false
                },
                {
                    id: 4,
                    code: 1,
                    name: '距离近到远',
                    isSelected: false
                },
            ],//排序条件list
            sort: 0,
            priceSortActive: 0//价格排序
        };
    },
    components: {
        HotelAreaSelector
    },
    methods: {
        //初始化
        init() {
            //初始化filter组件
            this.initFilters();
            //获取品牌连锁list
            this.getHotelBrandList();
        },

        //初始化filter组件
        initFilters() {
            //初始化位置区域选择组件
            this.$refs.hotelAreaSelector.init({
                cityId: this.cityId, // 城市id
            })
        },

        setValue(params) {
            let sortMenuItem = {};

            /*设置推荐排序*/
            this.sort = params.sort || 0;
            this.sortList.forEach(item => {
                item.isSelected = false;
                if (item.code === this.sort) {
                    item.isSelected = true;
                }
            })
        },

        //清空已选位置区域
        clearSelectedArea() {
            this.selectedArea = {};
            this.$refs.hotelAreaSelector.clearActiveItem();
        },

        //使用排序条件进行排序
        onSortClick(item) {
            this.sortList.forEach(item => {
                item.isSelected = false;
            })
            item.isSelected = true;
            this.sort = item.code;
            this.changeEmit('recommend');
        },

        //发布更改筛选条件
        changeEmit(str) {
            //区域位置筛选条件
            let areaInfo = this.selectedArea;
            let areaId = '';
            if (areaInfo.type === '002') {
                areaId = areaInfo.districtId;
            }

            //星级筛选条件
            const hotelStar = this.starValues.join(',');

            //价格筛选条件
            let minPrice = '';
            let maxPrice = '';
            if (this.minValue || this.maxValue) {
                minPrice = this.minValue ? Number(this.minValue) : '0';
                maxPrice = this.maxValue ? Number(this.maxValue) : '100000';
            }
            if (minPrice > maxPrice) {
                this.isAvailablePrice = false;
                return;
            }
            this.isAvailablePrice = true;

            //坐标筛选条件
            let pt = '';
            if (this.selectedArea.latitude && this.selectedArea.longitude) {
                pt = [this.selectedArea.latitude, this.selectedArea.longitude].join(',');
            }

            const isNear = this.sortActive === 2 ? 1 : '';

            let value = {};
            console.log('areaInfo', areaInfo)
            switch (str) {
                case 'recommend':
                    value = {
                        sort: this.sort,
                    };
                    break;
                case 'area':
                    value = {
                        areaId: areaId,
                        areaType: areaInfo.label ? areaInfo.type : '001',
                        areaName: areaInfo.label ? areaInfo.label : '',//已选的区域位置名字
                        lineCode: areaInfo.type === '003' ? areaInfo.lineCode : '',
                        stationValue: areaInfo.type === '004' ? areaInfo.firstValue : '',
                        // d: this.selectedDistance,
                        pt: areaInfo.longitude && areaInfo.latitude ? `${areaInfo.latitude},${areaInfo.longitude}` : '',// 经度，纬度
                        pt_from_type: '200'
                    };
                    if (areaInfo.label) {
                        this.$set(value, 'filterType', 0)
                    }
                    break;
                case 'star':
                    value = {
                        hotelStar
                    };
                    break;
                case 'price':
                    value = {
                        minPrice: minPrice,
                        maxPrice: maxPrice,
                    };
                    break;
                case 'brand':
                    value = {
                        hotelBrand: this.selectedBrandList.join(' '),//品牌连锁筛选条件
                    };
                    break;
                case 'popular':
                    value = {
                        differenceEnable: this.differenceEnable,//是否符合差标
                        agreementEnable: this.agreementEnable,//是否是协议酒店
                    };
                    break;
            }
            this.$emit('on-change', value);
        },

        //修改热门筛选
        onChangePopular(item) {
            item.isSelected = !item.isSelected;
            switch (item.id) {
                case 1:
                    this.differenceEnable = item.isSelected ? 1 : 0;
                    break;
                case 2:
                    this.agreementEnable = item.isSelected ? 1 : 0;
                    break;
            }
            this.changeEmit('popular');
        },

        //改变价格范围时
        onPriceRangeBlur() {
            let minPrice = '';
            let maxPrice = '';
            minPrice = this.minValue ? Number(this.minValue) : '';
            maxPrice = this.maxValue ? Number(this.maxValue) : '';

            //检查最低价和最高价是否合法
            if (this.minValue && this.maxValue) {
                if (minPrice > maxPrice) {
                    this.isAvailablePrice = false;
                    return;
                }
            }
            this.isAvailablePrice = true;

            //判断当前是否使用价格筛选
            if (this.minValue || this.maxValue) {
                this.filterList[1].isSelected = true;
            } else {
                this.filterList[1].isSelected = false;
            }

            //生成价格筛选提示标签
            let price = '';
            this.selectedPrice = '';
            if (minPrice && !maxPrice) {
                price = '≥￥' + minPrice;
            } else if (!minPrice && maxPrice) {
                price = '≤￥' + maxPrice;
            } else if (minPrice && maxPrice) {
                price = '￥' + minPrice + '-' + maxPrice;
            }
            this.selectedPrice = price;

            this.changeEmit('price');
        },

        //改变选中的位置区域
        changeArea(val) {
            this.districtActive = '';
            if (val.type === '002') {
                this.districtActive = val.districtId;
            }
            this.selectedArea = val;
            this.filterList[0].isSelected = true;
            setTimeout(() => {
                this.changeEmit('area');
            }, 500)
        },

        //选择星级
        onChooseStar(item) {
            let index = this.starValues.indexOf(item.id);
            if (index === -1) {
                this.starValues.push(item.id);
                this.selectedStarList.push(item);
                item.isSelected = true;
            } else {
                this.starValues.splice(index, 1);
                this.selectedStarList.splice(index, 1);
                item.isSelected = false;
            }
            if (this.starValues.length === 0) {
                this.filterList[1].isSelected = false;
            } else {
                this.filterList[1].isSelected = true;
            }
        },

        //获取品牌连锁列表
        async getHotelBrandList() {
            let res = await consumer_hotel_search_getHotelBrandList();
            this.brandList = res.datas;
            this.currBrandList = this.brandList.fresult;
        },

        //选中品牌连锁分类
        choseBrandClassified(classifiedItem) {
            this.brandClassifiedList.forEach(item => {
                this.$set(item, 'isSelected', false)
                if (item.id === classifiedItem.id) {
                    this.$set(item, 'isSelected', true)
                }
            });
            switch (classifiedItem.id) {
                case 1:
                    this.currBrandList = this.brandList.fresult;
                    break;
                case 2:
                    this.currBrandList = this.brandList.sresult;
                    break;
                case 3:
                    this.currBrandList = this.brandList.tresult;
                    break;
                case 4:
                    this.currBrandList = this.brandList.oresult;
                    break;
            }
        },

        //显示品牌连锁弹框
        showPopper(val) {
            this.showBrandPopperActive = val.type
        },

        //隐藏品牌连锁弹框
        hidePopper() {
            this.showBrandPopperActive = ''
        },

        //删除已选位置区域
        delSelectedArea() {
            this.selectedArea = {};
            this.districtActive = '';//已选的行政区筛选条件
            this.$refs.hotelAreaSelector.clearActiveItem();
            this.filterList[0].isSelected = false;
            this.changeEmit('area');
        },

        //删除已选位置区域
        delSelectedAreaNoEmit() {
            this.selectedArea = {};
            this.districtActive = '';//已选的行政区筛选条件
            this.$refs.hotelAreaSelector.clearActiveItem();
            this.filterList[0].isSelected = false;
        },

        //删除已选星级
        delSelectedStar(item) {
            let index = this.starValues.indexOf(item.id);
            this.starValues.splice(index, 1);
            this.selectedStarList.splice(index, 1);
            item.isSelected = false;
            if (this.starValues.length === 0 && this.selectedPrice === '') {
                this.filterList[1].isSelected = false;
            } else {
                this.filterList[1].isSelected = true;
            }
        },

        //删除已填的价格范围
        delSelectedPrice() {
            this.minValue = '';
            this.maxValue = '';
            this.selectedPrice = '';
            this.isAvailablePrice = true;
            if (this.starValues.length === 0 && this.selectedPrice === '') {
                this.filterList[1].isSelected = false;
            } else {
                this.filterList[1].isSelected = true;
            }
            this.changeEmit('price');
        },

        //删除已选的品牌范围
        delSelectedBrand(item) {
            let index = this.selectedBrandList.indexOf(item);
            this.selectedBrandList.splice(index, 1);
            if (this.selectedBrandList.length === 0) {
                this.filterList[2].isSelected = false;
            } else {
                this.filterList[2].isSelected = true;
            }
        },

        //清空所有已选筛选条件
        clearAll() {
            this.selectedArea = {};//已选区域位置的坐标
            this.districtActive = '';//已选的行政区筛选条件
            this.starValues = [];//已选的星级idlist
            this.selectedStarList = [];//已选的星级对象list
            this.minValue = '';//最低价
            this.maxValue = '';//最高价
            this.selectedPrice = '';//已填的价格范围提示标签
            this.isAvailablePrice = true;//价格范围是否有效
            this.selectedBrandList = [];//已选的品牌连锁list
            this.$refs.hotelAreaSelector.clearActiveItem();
            this.filterList.forEach((item) => {
                item.isSelected = false;
            })
            this.starList.forEach((item) => {
                item.isSelected = false;
            })
        }
    },
    watch: {
        //监测城市的变化
        cityId() {
            this.initFilters();
        },

        //监测已选位置区域
        selectedArea(newVal, oldVal) {
            if (!oldVal.type && newVal.type) {
                this.$emit('on-area-change');
            }
        },

        //监测已选星级
        starValues() {
            this.changeEmit('star');
        },

        //监测已选品牌连锁
        selectedBrandList() {
            if (this.selectedBrandList.length === 0) {
                this.filterList[2].isSelected = false;
            } else {
                this.filterList[2].isSelected = true;
            }
            this.changeEmit('brand');
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.init();
        })
    }
}
