//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 防抖函数
import debounce from 'lodash.debounce';
// 搜索联想
import consumer_hotel_search_searchThink
    from "@/lib/data-service/haolv-default/consumer_hotel_search_searchThink.js";
import HotelCitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/2.1.6/index.vue";
//选择时间组件
import HotelStayDatePicker
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-stay-date-picker/import_latest_version_module';
import HotelKeywordSelector
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-keyword-selector/1.0.2/index.vue";
import moment from "moment";

export default {
    props: {
        allowChangeJourneyTime: {
            type: Number,
            default: 0,
        },
        evectionDetailType: {
            type: String,
            default: '',
        },
        evectionType: {
            type: Number,
            default: 1,
        },
        applyJourneyId: {
            type: Number,
            default: 0,
        },
        cityInfo: {
            type: Object,
            default: null,
        },
        date: {
            type: Array,
            default() {
                return [];
            },
        },
        travelDate: {
            type: Array,
            default() {
                return [];
            },
        },
        keyword: {
            type: String,
            default: '',
        },
        evectionRequired: {
            type: [String, Number],
            default: 0
        },
        cityCanEdit: {
            type: Boolean,
            default: true
        },
        timeCanEdit: {
            type: Boolean,
            default: true
        },
        unlimitedJourney: {
            type: Number,
            default: 0
        }
    },
    computed: {
        showNotChangeModel() {
            return ((this.evectionDetailType === '001' || this.evectionDetailType === '003') && this.unlimitedJourney === 0)
        }
    },
    components: {
        HotelCitySelector,
        HotelKeywordSelector,
        HotelStayDatePicker
    },
    data() {
        return {
            startRangeDate: '', // 开始范围
            endRangeDate: '', // 结束范围
            startPickerOptions: {
                disabledDate: this.disabledStartDate
            },
            endPickerOptions: {
                disabledDate: this.disabledEndDate
            },
            query: {
                city: null,
                date: [],
                keyword: '',
                filterType: '',
                pt: ''
            },
            visible: false,
            thinkList: [],

            isInit: false,

            timeOptionRange: null,
            hotelPickerOptions: {
                disabledDate: this.hotelDisabledDate,
                onPick: this.hotelOnPick
            },
            yesterday: '',
            isMorning: false,//当前时间是否为凌晨00:00-06:00
        };
    },
    methods: {
        disabledStartDate(time) {
            const currentTime = time.getTime()
            const nowDate = Date.now() - 1000 * 3600 * 24
            const startRangeDate = moment(this.startRangeDate).valueOf()
            const endRangeDate = moment(this.endRangeDate).valueOf()
            if (this.evectionDetailType === '001') {
                let isCanSelectYesterday = this.yesterday === this.startRangeDate;
                isCanSelectYesterday = true
                if (isCanSelectYesterday) {
                    //凌晨进入页面，如果出差单开始日期为昨天，则可选昨天为入住日期
                    let yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    let timestamp = yesterday.getTime();
                    return currentTime < (timestamp - 8.64e7) || (currentTime > endRangeDate);
                } else {
                    return currentTime < nowDate || currentTime < startRangeDate || currentTime > endRangeDate
                }
            } else {
                if (this.isMorning) {
                    //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                    let yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    let timestamp = yesterday.getTime();
                    return currentTime < (timestamp - 8.64e7);
                } else {
                    //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                    return currentTime < nowDate;
                }
            }
        },
        disabledEndDate(time) {
            const currentTime = time.getTime()
            const nowDate = Date.now() - 1000 * 3600 * 24
            const startRangeDate = moment(this.startRangeDate).valueOf()
            const endRangeDate = moment(this.endRangeDate).valueOf()
            let depDate = ''
            if (this.startRangeDate !== '') {
                depDate = moment(this.startRangeDate).valueOf()
            }

            if (this.evectionDetailType === '001' && depDate !== '') {
                // 因公带出差单 选了出发时间
                return currentTime < nowDate || currentTime < startRangeDate || currentTime > endRangeDate || currentTime < depDate
            } else if (this.evectionDetailType === '001' && depDate === '') {
                // 因公带出差单 没有选出发时间
                return currentTime < nowDate || currentTime < startRangeDate || currentTime > endRangeDate
            } else if (this.evectionDetailType !== '001' && depDate !== '') {
                // 没有出差单 选了出发时间
                return currentTime < nowDate || currentTime < depDate;
            } else {
                // 没有出差单 没选出发时间
                return currentTime < nowDate;
            }
        },
        onSearch() {
            if (this.query.filterType === 1) {
                this.$emit('on-keyword-change', {
                    filterType: this.query.filterType
                });
            }
            this.changeEmit();
        },
        changeEmit(keywordStr) {
            const cityId = Number(this.query.city.cityId);
            const cityName = this.query.city.cityName;
            const filterType = this.query.filterType;
            const pt = filterType === 1 ? this.query.pt : '';
            let checkInDate = '';
            let checkOutDate = '';
            if (this.evectionType == '001') {
                checkInDate = this.$moment(this.startRangeDate).format('YYYY-MM-DD');
                checkOutDate = this.$moment(this.endRangeDate).format('YYYY-MM-DD');
            } else {
                checkInDate = this.$moment(this.query.date[0]).format('YYYY-MM-DD');
                checkOutDate = this.$moment(this.query.date[1]).format('YYYY-MM-DD');
            }
            const keyword = keywordStr ? keywordStr : this.query.keyword;

            let query = {
                cityId,
                cityName,
                checkInDate,
                checkOutDate,
                keyword,
                filterType,
                pt_from_type: '100'
            };
            if (filterType === 1) {
                this.$set(query, 'pt', pt);
            }
            this.$emit('on-change', query);
        },
        checkEvectionRequired() {
            this.$message({
                type: "warning",
                message: '不能修改出差单既定行程'
            })
        },
        blurHotelDate() {
            this.timeOptionRange = null
        },
        hotelDisabledDate(time) {
            if (this.isMorning) {
                //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                let timestamp = yesterday.getTime();
                let isCanSelectYesterday = this.yesterday === this.travelDate[0];
                if (isCanSelectYesterday) {
                    //凌晨进入页面，如果出差单开始日期为昨天，则可选昨天为入住日期
                    return currentTime < (timestamp - 8.64e7) || (currentTime > endRangeDate);
                } else {
                    return currentTime < (timestamp - 8.64e7) || currentTime < startRangeDate || currentTime > endRangeDate
                }
            } else {
                //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                return time.getTime() < moment(this.travelDate[0]).valueOf() || time.getTime() > moment(this.travelDate[1]).valueOf();
            }
        },
        hotelOnPick({maxDate, minDate}) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        initHotelCitySelector() {
            const __this = this;
            const aSelector = __this.$refs.HotelCitySelector;
            aSelector.init({
                mock_data: {
                    enable: false,
                    wait_seconds: 0.5,
                },
                panel_select_entity: {
                    width: `440`,
                },
                el_autocomplete: {
                    placeholder: `请选择目的城市`,
                },
                event_handler: {
                    change_event_handler(args) {
                        if (__this.query.city && __this.query.city.cityId !== args.id) {
                            __this.query.city = {
                                cityId: args.id,
                                cityName: args.name
                            }
                        }
                        if (args.type !== '5') {
                            __this.query.keyword = args.key ? args.key : '';
                        } else {
                            __this.query.keyword = '';
                        }
                        if (args.entity.filterType === 1) {
                            __this.query.pt = args.entity.location;
                        }
                        __this.query.filterType = args.entity.filterType || 0;
                    },
                },
            });
        },
        setHotelCitySelectorVal() {
            const __this = this;
            const aSelector = __this.$refs.HotelCitySelector;
            aSelector.set_value({
                "id": __this.query.city.cityId,
                "name": __this.query.city.cityName,
                "entity": {
                    "cityId": __this.query.city.cityId,
                    "cityCn": __this.query.city.cityName,
                    "cityPinyin": "",
                    "cityPinyinSimp": "",
                    "initial": "",
                    "isHot": "",
                    "cityName": null,
                    "key": null
                },
            });
        },
        changeKeyword(val) {
            this.query.pt = '';
            this.query.filterType = val.filterType;
            if (val.filterType === 1) {
                this.query.pt = val.location;
            }
        },
        setKeyword(keyword) {
            this.query.keyword = keyword;
        },
        clearKeyword() {
            this.query.keyword = '';
            this.query.filterType = '';
        },
    },
    watch: {
        cityInfo(value) {
            this.$set(this.query, 'city', value);
        },
        'query.date'(newVal) {
            this.startRangeDate = this.$moment(newVal[0]).format('YYYY-MM-DD');
            this.endRangeDate = this.$moment(newVal[1]).format('YYYY-MM-DD');
        }
    },
    filters: {
        filterWeek(val) {
            if (val === '' || !val) {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    mounted() {
        this.$set(this.query, 'date', this.date);
        this.initHotelCitySelector();
        setTimeout(() => {
            this.startRangeDate = this.query.date[0] // 开始范围
            this.endRangeDate = this.query.date[1] // 结束范围
            if (this.evectionDetailType === '001') {
                this.$refs['hotelStayDatePicker'].init({
                    __this: this,
                    mode: '100',
                    applyJourneyId: parseInt(this.applyJourneyId) || 0,
                    timeCanEdit: this.timeCanEdit
                })
            }

            const _this = this;
            this.setHotelCitySelectorVal();
            this.$set(this.query, 'keyword', this.keyword);
            this.$refs['hotelKeywordSelector'].init({
                get_params() {
                    return {
                        cityId: _this.query.city && _this.query.city.cityId ? _this.query.city.cityId : '',
                    };
                },
            })
        }, 500);

        //判断是否凌晨（00:00-06:00）进入页面
        let curTimeList = this.$moment().toArray();
        this.isMorning = curTimeList[3] < 6;
        this.yesterday = this.$moment().add(-1,'days').format('YYYY-MM-DD');
    },
}
