// 酒店列表
// http://192.168.0.19:8765/doc.html#/haolv-consumer/hotel-search-controller/hotelListUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_hotel_search_hotelList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/hotel/search/hotelList',
        data: {params: pParameter }
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_hotel_search_hotelList;