//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            emptyImg: 'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/05/26/list_empty_3.0.png?x-oss-process=style/haolv-default'
        }
    },
    methods: {
        // onSearch() {
        //   this.$emit('on-change', {});
        // },
    },
}
