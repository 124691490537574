//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'

export default {
    props: {
        isShowStandard: {
            type: Boolean,
            default() {
                return true;
            }
        },
        evectionDetailType: {
            type: String,
            default() {
                return '101';
            }
        },
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        landmark: {
            type: String,
            default() {
                return '';
            }
        },
    },
    components: {
        EvectionStandardExceed
    },
    filters: {
        formatStar(val) {
            switch (Number(val)) {
                case 20:
                    return "经济型";
                case 25:
                    return "经济型";
                case 30:
                    return "舒适型";
                case 35:
                    return "舒适型";
                case 40:
                    return "高档型";
                case 45:
                    return "高档型";
                case 50:
                    return "豪华型";
                case 55:
                    return "豪华型";
            }
            ;
        },
    },
}
