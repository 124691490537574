// 酒店推荐列表
// https://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/API/hotelListRecommendV2UsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_hotel_search_hotelListRecommendV2 = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/hotel/search/hotelListRecommendV2',
        data: {params: pParameter }
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_hotel_search_hotelListRecommendV2;